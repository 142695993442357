import React from 'react';
import {Link} from "react-router-dom";

export const CartEmpty = () => {
    return (
       <>
           <div className='cart cart--empty'>
               <h2>Корзина пустая </h2>
               <p>
                   Вероятней всего вы не заказывали пиццу. <br/>
                   Для того чтобы заказать пиццу перейдите на главную страницу.
               </p>
               <img src='' alt='Empty cart'/>
               <Link to='/' className='button button--black'>
                   <span>Вернуться назад</span>
               </Link>
           </div>
       </>
    );
};

